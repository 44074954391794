import React, {Component} from 'react';
import { Icon } from 'semantic-ui-react'
import { DateInput } from 'semantic-ui-calendar-react';

import './App.css';

function initStartStopDate() {
  const now = new Date();
  //console.log('initStartStopDate:', now)
  var strD = now.getDate();
  var strM = now.getMonth()+1;
  var strY = now.getFullYear();
  //console.log('initStartStopDate:', strD, strM, strY)
  strD = ("0" + strD).slice(-2);
  strM= ("0" + strM).slice(-2);

  return {start: `${strD}-${strM}-${strY}`, 
  stop: `${strD}-${strM}-${strY+1}`} ;
}

class App extends Component  {
  constructor(props) {
    super(props);
    
    const initDates = initStartStopDate();
    this.state = {
      sideInfoVisible: false,
      startDate: initDates.start,
      stopDate:initDates.stop,
      time: '',
      dateTime: '',
      datesRange: '',
      deltaT:'10',
      showLoader:false
    }
    this.onOpenSideInfo = this.onOpenSideInfo.bind(this);
    this.onCloseSideInfo = this.onCloseSideInfo.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChangeDeltaT = this.onChangeDeltaT.bind(this);
}
  
componentDidMount() {
  setTimeout(
    () => {             
      this.reloadData();            
  }, 500);
  
}
onOpenSideInfo () {
  this.setState({showLoader: false, sideInfoVisible:true})
}

onCloseSideInfo () {
  this.setState({sideInfoVisible:false})
}
handleChange = (event, {name, value}) => {
  if (this.state.hasOwnProperty(name)) {
    console.log('name=', value)
    this.setState({showLoader:false, [name]: value }, ()=> this.reloadData());
  }
}
onChangeDeltaT = (event, data) => {
  this.setState({showLoader:false, deltaT: event.target.value}, ()=> this.reloadData());
  console.log('deltaT', event.target.value);
  
}

parseTime(str) {

  var point1 = str.indexOf('-');
  var point2 = str.lastIndexOf('-');

  var strD = parseInt(str.substring(0, point1));
  var strM = parseInt(str.substring( point1+1, point2));
  var strY = parseInt(str.substring( point2+1, str.length));
  //console.log(strD, strM, strY)
  strD = ("0" + strD).slice(-2);
  strM= ("0" + strM).slice(-2);
  return `${strY}-${strM}-${strD}`;
}

reloadData() {
  if( this.state.startDate!=='' && this.state.stopDate!=='' && 
      this.state.deltaT!=='') {
        //console.log('reloadData=', window.LOADDATA)
        if(window.LOADDATA===0 || window.LOADDATA===3) {
          window.DELTAT = this.state.deltaT;
          this.parseTime(this.state.startDate);
          window.STARTTIME = this.parseTime(this.state.startDate);
          window.ENDTIME = this.parseTime(this.state.stopDate);
          window.LOADDATA = 1;
          window.SIMULATE = 1;
          this.setState({showLoader:true});
          //console.log('reloadData=', window.LOADDATA, window.SIMULATE )
        }
  } 
  
  
}
renderSideInfo() { 

  return(
  <div className="sideInfo">
     <div className="sideInfoButtonClose" 
          onClick={this.onCloseSideInfo}>
            <Icon name='close' size='large' />
     </div>
     <div className="sideInfoComponets" >
     <DateInput
       animation='none'
       closable={true}
       closeOnMouseLeave={true}
       hideMobileKeyboard={true}
       name="startDate"
       placeholder="Start Date"
       value={this.state.startDate}
       iconPosition="left"
       onChange={this.handleChange}
       style={{width:'95%', margin:'0 0 5px 0'}}
       />
       <DateInput
       animation='none'
       closable={true}
       closeOnMouseLeave={true}
       hideMobileKeyboard={true}
       name="stopDate"
       placeholder="Stop Date"
       value={this.state.stopDate}
       iconPosition="left"
       onChange={this.handleChange}
       style={{width:'95%', margin:'0 0 5px 0'}}
       /> 
       

       <div className="ui inline input">         
        <input type="range"
          min="1" 
          max="100" 
          step="10"  
          id="myRange" 
          value={this.state.deltaT}
          onChange={this.onChangeDeltaT}   />
       
      </div>

     </div>    
  </div>)
}


renderSideButton() {
  return (
    <div className="sideInfoButton" onClick={this.onOpenSideInfo}>
      <Icon name='sidebar' size='large' />
    </div>
  );
}
renderLoaderButton(){

  return (
    <div id="myLoader" className="red-square">
      <div className="loader" id="dataloader"/>
     </div>)
}
//
  render() {

    console.log('this.state.showLoader', this.state.showLoader)
      return (
        <React.Fragment>
          {this.state.sideInfoVisible && this.renderSideInfo()}
          {!this.state.sideInfoVisible && this.renderSideButton()}
          {this.state.showLoader  && this.renderLoaderButton()}
          <div id="full">
            <div id="myCanvas"/>    
          </div>
        </React.Fragment>
      );
    }
}

export default App;
